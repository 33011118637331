module.exports = function (boApp)
{
  var Utils = require("./utils.js");

  boApp.controller('AlertCtrl', function ($scope, Alert) {
    $scope.getAlerts = function () {
      return Alert.getAlerts();
    };

    $scope.closeAlert = function (index) {
      Alert.closeAlert(index);
    };
  });


  boApp.controller('CommentCtrl', function ($rootScope, $scope, commentProvider) {
    $scope.comments = [];
    $scope.commentFormHidden = true;
    $scope.comment.content = null;
    $scope.totalItems = 0;
    $scope.filter = {
      content_type: $scope.comment.content_type,
      object_id: $scope.comment.object_id,
      page: 1,
      ps: 10
    };

    loadPage(); // add polling for automatic refreshing

    $scope.postComment = function () {
      if ($scope.comment.hasOwnProperty('id')) {
        var index = $scope.comments.indexOf($scope.comment);
        commentProvider.put($scope.comment.id, $scope.comment).then(function (data) {
          $scope.comments[index] = data;
          resetComment();
        });
      } else {
        commentProvider.post($scope.comment).then(function (data) {
          $scope.comments.unshift(data);
          resetComment();
        });
      }
    };

    $scope.$watch('commentFormHidden', function (value) {
      if (!value) {
        document.getElementById('comment-content').focus();
      }
    });

    $scope.editComment = function (comment) {
      $scope.commentFormHidden = false;
      $scope.comment = comment;
    };

    $scope.deleteComment = function (comment) {
      var index = $scope.comments.indexOf(comment);
      commentProvider.del(comment.id).then(function (data) {
        $scope.comments.splice(index, 1);
        resetComment();
      });
    };

    $scope.fieldClass = function (field) {
      if (!field || !field.hasOwnProperty('$dirty') || !field.$dirty) return;
      return field.$valid ? 'has-success' : 'has-error';
    };

    $scope.hasMore = function () {
      return $scope.comments.length < $scope.totalItems;
    };
    $scope.more = function () {
      if ($scope.hasMore()) {
        $scope.filter.page++;
        loadPage();
      }
    };

    function resetComment () {
      $scope.comment.content = null;
      if ($scope.comment.hasOwnProperty('id'))
        delete $scope.comment.id;
      $scope.commentFormHidden = true;
    }

    function loadPage () {
      commentProvider.filter($scope.filter).then(function (data) {
        $scope.comments = $scope.comments.concat(data.results);
        $scope.totalItems = data.count;
      });
    }
  });

  boApp.controller('CompanyCtrl', function ($scope, $routeParams, companyProvider, maxPages, pageSize) {
    $scope.currentPage = 1;
    $scope.maxPages = maxPages;
    $scope.pageSize = pageSize;

    $scope.pageChanged = function () {
      Utils.pageChanged($scope, companyProvider);
    };

    if ($routeParams.hasOwnProperty('company')) {
      companyProvider.get($routeParams.company).then(function (company) {
        $scope.company = company;
        $scope.comment = {
          content_type: company.content_type,
          object_id: company.id
        };
      });
    } else {
      Utils.pageChanged($scope, companyProvider);
    }

    $scope.orderProp = 'id';
  });


  boApp.controller('WarehouseCtrl',
  function ($scope, $routeParams, $q, $uibModal, $rootScope,
      warehouseProvider, stockProvider, articleProvider, maxPages, pageSize, apiUrl) {

    $scope.filter = {};
    $scope.search = '';

    if ($routeParams.hasOwnProperty('warehouseId')) {
      warehouseProvider.get($routeParams.warehouseId).then(function (warehouse) {
        $scope.warehouse = warehouse;
        warehouse.stocks.sort(function (a, b) {
          if (a.lot.product.name > b.lot.product.name) return 1;
          if (a.lot.product.name < b.lot.product.name) return -1;
          return 0;
        });
        $scope.comment = {
          content_type: warehouse.content_type,
          object_id: warehouse.id
        };
        $scope.filter['warehouse'] = warehouse.id;
      });
    } else {
      $scope.tableConfig = {
        itemsPerPage: pageSize,
        'maxPages': maxPages
      };

      $scope.filter_text = {
        'q':'',
        'ps':100
      };

      $scope.warehouses = [];
      function loadPage () {
        Utils.loadAllPaginatedResults($q, warehouseProvider, $scope.filter_text).then(function (results) {
          $scope.warehouses = results;
        });
      }
      loadPage();

      $scope.$watch('filter_text.q', function (newValue, oldValue) {
        if (newValue != oldValue)
          loadPage();
      });

    }

    $scope.apiUrl = apiUrl;
    $scope.orderProp = 'id';


    $scope.progressBarType = function (warehouse) {
      if (warehouse.stock_level >= 60) return 'success';
      if (warehouse.stock_level >= 25) return 'warning';
      if (warehouse.stock_level >= 0) return 'danger';
      return 'info';
    };

    $scope.openComponentModal = function (product) {
      Utils.openProductPhotoModal(product, $uibModal);
    };
  });


  boApp.controller('OnDemandCtrl',
  function ($http, $scope, $q, $uibModal, $timeout, $location, ondemandProvider, transactionProvider,
      companyProvider, Alert, maxPages, pageSize, apiUrl) {

    $scope.ajaxLoader = require('../img/ajax-loader.gif');

    $scope.selectedDeliveryHub = window.localStorage.getItem('delivery_hub');
    $scope.areas = [
      { name: 'Saint-Ouen', value: '1' },
      { name: 'Lyon', value: '2' },
      { name: 'Marseille', value: '3' },
      { name: 'Bordeaux', value: '7' },
      { name: 'Tout', value: null },
    ];
    $scope.currentPage = 1;
    $scope.currentPageDelivery = 1;
    $scope.maxPages = maxPages;
    $scope.pageSize = pageSize;
    $scope.loading = 0;

    $scope.companies = {}
    $scope.loading++;
    Utils.loadAllPaginatedResults($q, companyProvider, {'ps':100}).then(function (results) {
      $scope.loading--;
      results.forEach(function (company) {
        $scope.companies[company.id] = company.name;
      });
    });

    $scope.companyName = function (tx) {
      if ($scope.companies.hasOwnProperty(tx.company))
        return $scope.companies[tx.company];
      return '-';
    };

    $scope.stateLabel = function (tx) {
      if (tx.od_served)
        return "Livrée"
      if (tx.od_ready)
        return "Préparée"
      return "En attente"
    };

    $scope.setReady = function (tx) {
      $scope.saving = true;
      ondemandProvider.patch(tx.id, {'od_ready': new Date()})
        .then(function () {
          $scope.saving = false;
          Alert.showMessage('success', "Commande passée à l'état prête.");
          loadOrders();
        });
    };

    $scope.unsetReady = function (tx) {
      $scope.saving = true;
      ondemandProvider.patch(tx.id, {'od_ready': null})
        .then(function () {
          $scope.saving = false;
          Alert.showMessage('success', "Commande mise en attente.");
          loadOrders();
        });
    };


    $scope.setServed = function (tx) {
      $scope.saving = true;
      ondemandProvider.patch(tx.id, {'od_served': new Date()})
        .then(function () {
          $scope.saving = false;
          Alert.showMessage('success', "Commande passée à l'état livrée.");
          loadOrders();
        });
    };

    $scope.unsetServed = function (tx) {
      $scope.saving = true;
      ondemandProvider.patch(tx.id, {'od_served': null})
        .then(function () {
          $scope.saving = false;
          Alert.showMessage('success', "Commande passée à l'état prête.");
          loadOrders();
        });
    };

    $scope.setPreparedAll = function (deliverySiteId) {
      $scope.saving = true;
      $http.patch(apiUrl+`/api/ondemand/orders/?delivery_site=${deliverySiteId}`,
          {'transition': 'prepare'})
          .then(function (response) {
            $scope.saving = false;
            Alert.showMessage('success', "Site passé à l'état prêt.");
            loadOrders();
          });
    };

    $scope.setServedAll = function (deliverySiteId) {
      $scope.saving = true;
      $http.patch(apiUrl+`/api/ondemand/orders/?delivery_site=${deliverySiteId}`,
          {'transition': 'deliver'})
      .then(function (response) {
        $scope.saving = false;
        Alert.showMessage('success', "Site passé à l'état livrée.");
        loadOrders();
      });
    };

    loadOrders();

    function loadOrders () {
      //Ondemand en attente
      $scope.loading++;
      Utils.loadAllPaginatedResults($q, ondemandProvider, {
        is_ready: false,
        is_served: false,
        on_demand_delivery_hub: $scope.selectedDeliveryHub,
        ps: 100,
      }).then(function (results) {
        $scope.loading--;
        $scope.placedTxs = filterCompany(results);
        $scope.lengthPlacedTxs = results.length;
      });
      //Ondemand prêtes
      $scope.loading++;
      Utils.loadAllPaginatedResults($q, ondemandProvider, {
        is_ready: true,
        is_served: false,
        on_demand_delivery_hub: $scope.selectedDeliveryHub,
        ps: 100,
      }).then(function (results) {
        $scope.loading--;
        $scope.readyTxs = filterCompany(results);
        $scope.lengthReadyTxs = results.length;
      });
      //Ondemand livrées
      Utils.pageChanged($scope, ondemandProvider, {
        is_served: true,
        on_demand_delivery_hub: $scope.selectedDeliveryHub,
      });
    }

    $scope.pageChanged = function() {
      $scope.currentPage =  $scope.currentPageDelivery;
      Utils.pageChanged($scope, ondemandProvider, {
        is_served: true,
        on_demand_delivery_hub: $scope.selectedDeliveryHub,
      });
    };

    $scope.deliveryHubChanged = function() {
      window.localStorage.setItem('delivery_hub', $scope.selectedDeliveryHub);
      loadOrders();
    }

    function filterCompany (results){
      var listCompany = [];
      for (data in results){
        if (results[data].hasOwnProperty('company')){
          if(results[data].company in listCompany){
            listCompany[results[data].company].push(results[data]);
          } else {
            listCompany[results[data].company] = [];
            listCompany[results[data].company].push(results[data]);
          }
        }
      }
      return listCompany;
    }

  });
};
